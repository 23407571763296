import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import TitleArea from '../components/TitleArea'
import { Link } from 'gatsby'
import {
  Container,
  Title,
  Columns,
  Column,
  Section,
  Field,
  Label,
  Control,
  Input,
  TextArea,
  Button,
  Notification,
  Content,
} from 'bloomer'

export default () => (
  <Layout>
    <SEO title="Request a Reservation" alias="request-reservation" />
    <TitleArea title="Request a Reservation" />
    <Section>
      <Container className="container--narrow">
        <Columns isMultiline>
          <Column isSize="1/2">
            <Content>
              <h3>Address</h3>
              <address>
                Shady Lane Boarding Kennel
                <br />
                RR1 1050 Bruce Road 86
                <br />
                Lucknow, Ont N0G 2H0
              </address>
              <h3>Phone Number</h3>
              <p>
                <a href="tel:5195282938">(519) 528-2938</a>
              </p>
              <p>
                Feel free to come and visit our kennel any time during{' '}
                <a href="#contact-form">business hours</a>.
              </p>
            </Content>
          </Column>
          <Column isSize="1/2">
            <form
              name="request-reservation"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              {/* You still need to add the hidden input with the form name to your JSX form */}
              <input
                type="hidden"
                name="form-name"
                value="request-reservation"
              />
              <Title tag="h3" isSize={4}>
                Owner Information
              </Title>

              <Field>
                <Label>Name</Label>
                <Control>
                  <Input type="text" name="name" />
                </Control>
              </Field>

              <Field>
                <Label>Phone</Label>
                <Control>
                  <Input type="tel" name="phone" />
                </Control>
              </Field>

              <Field>
                <Label>Email</Label>
                <Control>
                  <Input type="email" name="email" />
                </Control>
              </Field>

              <Field>
                <Label>Town</Label>
                <Control>
                  <Input type="text" name="town" />
                </Control>
              </Field>

              <Title tag="h3" isSize={4}>
                Dates
              </Title>
              <Field>
                <Label>Arrival date</Label>
                <Control>
                  <Input type="date" name="arrival_date" />
                </Control>
              </Field>
              <Field>
                <Label>Departure date</Label>
                <Control>
                  <Input type="date" name="departure_date" />
                </Control>
              </Field>

              <Title tag="h3" isSize={4}>
                Pet Information
              </Title>
              <Field>
                <Label>Number of dogs</Label>
                <Control>
                  <Input type="number" name="Number of dogs" />
                </Control>
              </Field>
              <Field>
                <Label>Number of cats</Label>
                <Control>
                  <Input type="number" name="Number of cats" />
                </Control>
              </Field>

              <Field>
                <Label>Pet names and descriptions </Label>
                <Control>
                  <TextArea name="pets" />
                </Control>
              </Field>

              {/* <Title tag="h3" isSize={4}>
                Walking
              </Title>
              <Field>
                <Label>
                  How many walks a day would your dog like?{' '}
                  <Link to="/dog-walking">
                    (find out more about our dog walking service)
                  </Link>
                </Label>
                <Control>
                  <Input type="number" name="walks_number" />
                </Control>
              </Field> */}
              <Title tag="h3" isSize={4}>
                Additional notes
              </Title>
              <Field>
                <Label>Any additional information or requests?</Label>
                <Control>
                  <TextArea name="notes" />
                </Control>
              </Field>
              <p>
                <strong>
                  We try to respond to all requests within 24 hours.
                </strong>
              </p>
              <Field isGrouped>
                <Control>
                  <Button isColor="primary" type="submit">
                    Submit
                  </Button>
                </Control>
              </Field>
            </form>
          </Column>
        </Columns>
      </Container>
    </Section>
  </Layout>
)
