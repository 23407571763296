import React from 'react'
import {
  Hero,
  HeroBody,
  Container,
  Title,
  Subtitle,
} from 'bloomer'

const TitleArea = ({ title, subtitle }) => (
  <Hero
  // isSize="small"
  // isColor="primary"
  isColor="info"
  // isBold
>
  <HeroBody>
    <Container className="container--narrow">
      <Title isSize={1}>{title}</Title>
      <Subtitle isSize={3}>{subtitle}</Subtitle>
    </Container>
  </HeroBody>
</Hero>
)

export default TitleArea
